import { render, staticRenderFns } from "./GameSelectorSidebar.vue?vue&type=template&id=3a3606f3&scoped=true&lang=html&"
import script from "./GameSelectorSidebar.vue?vue&type=script&lang=js&"
export * from "./GameSelectorSidebar.vue?vue&type=script&lang=js&"
import style0 from "./GameSelectorSidebar.vue?vue&type=style&index=0&id=3a3606f3&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a3606f3",
  null
  
)

export default component.exports